import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//3rd party stuff
import { NgxLoadingModule } from 'ngx-loading';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { MyStoryComponent } from './my-story/my-story.component';
import { KeyProjectsComponent } from './key-projects/key-projects.component';
import { MySkillsComponent } from './my-skills/my-skills.component';
import { HttpClientModule } from '@angular/common/http';
import { DbApiService } from './services/db-api.service';
import { FooterComponent } from './footer/footer.component';
import { ProjectComponent } from './project/project.component';
import { ResumeComponent } from './resume/resume.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    MyStoryComponent,
    KeyProjectsComponent,
    MySkillsComponent,
    FooterComponent,
    ProjectComponent,
    ResumeComponent
  ],
  imports: [
    BrowserModule, FormsModule, ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxLoadingModule.forRoot({}),
    NgbModule.forRoot()
  ],
  providers: [
    DbApiService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
