import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MyStoryComponent } from './my-story/my-story.component';
import { KeyProjectsComponent } from './key-projects/key-projects.component';
import { ResumeComponent } from './resume/resume.component';

const routes: Routes = [
  {path: '', component: MyStoryComponent},
  {path: 'key-projects', component:KeyProjectsComponent},
  {path: 'resume', component:ResumeComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
