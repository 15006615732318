import { Component, OnInit, Input } from '@angular/core';
import { DbApiService } from '../services/db-api.service';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css'],
  providers: [NgbCarouselConfig]
})
export class ProjectComponent implements OnInit {
@Input() project;
imgPath = '../assets/images/projects/';


  constructor(private dbApiService:DbApiService, config: NgbCarouselConfig) {
    config.interval = 10000;
    config.wrap = true;
    config.keyboard = false;
    config.pauseOnHover = true;
    config.showNavigationIndicators = true;
  }

  ngOnInit() {

  }

}
