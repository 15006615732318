import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';

@Injectable()
export class DbApiService {
  path = environment.path; 
  /////////////////////////////////path ='http://localhost:3000';

  constructor(private http:HttpClient) { }

  getSkills(){
    return this.http.get<any>(this.path + '/skills');
  }

  getStory(){
     return this.http.get<any>(this.path + '/story');
  }

  getProject(name){
    return this.http.get<any>(this.path + '/projects/' + name)
  }
}
