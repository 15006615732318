import { Component, OnInit } from '@angular/core';
import { DbApiService } from '../services/db-api.service';

@Component({
  selector: 'app-my-story',
  templateUrl: './my-story.component.html',
  styleUrls: ['./my-story.component.css']
})
export class MyStoryComponent implements OnInit {
  story = [];
  loading = false;
  constructor(private dbApiService: DbApiService) { }

  ngOnInit() {
    this.loading = true;
    this.dbApiService.getStory().subscribe(data => {
      data.sort((a,b) => (a.story > b.story) ? 1 : ((b.story > a.story) ? -1 : 0));
      this.story = data;
      this.loading = false;
    })
  }

}
