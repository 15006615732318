import { Component, OnInit } from '@angular/core';
import { DbApiService } from '../services/db-api.service';

@Component({
  selector: 'app-key-projects',
  templateUrl: './key-projects.component.html',
  styleUrls: ['./key-projects.component.css']
})
export class KeyProjectsComponent implements OnInit {
  projectName = "bb";
  project;
  loading: boolean = false;
  constructor(private dbApiService: DbApiService) { }

  ngOnInit() {
    this.updateProject(this.projectName);
  }

  updateProject(projectName){
    this.loading = true;
    this.projectName = projectName;
    this.dbApiService.getProject(this.projectName).subscribe(data => {
      this.project = data;
      this.loading = false;
    })
  }

}
