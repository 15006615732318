import { Component, OnInit } from '@angular/core';
import { DbApiService} from '../services/db-api.service';

@Component({
  selector: 'app-my-skills',
  templateUrl: './my-skills.component.html',
  styleUrls: ['./my-skills.component.css']
})
export class MySkillsComponent implements OnInit {
  loading = false;
  skills = [];
  imgPath = '../assets/images/';
  constructor(private dbApiService: DbApiService) { }

  ngOnInit() {
    this.loading = true;
    this.dbApiService.getSkills().subscribe(data => {
      data.sort((a,b) => (b.priority > a.priority) ? 1 : ((a.priority > b.priority) ? -1 : 0));
      this.skills = data;
      this.loading = false;
    })
  }

}
